module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"71e60a49877713059dc6ab41b0d20d5b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N7QTGZ7","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["fr","en"],"defaultLanguage":"fr","fallbackLanguage":"en","siteUrl":"https://lafraise.app","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"trailingSlash":"always","redirect":false,"pages":[{"matchPath":"/:lang?","getLanguageFromPath":false,"excludeLanguages":["fr","en"]},{"matchPath":"/:lang?/categories","getLanguageFromPath":false,"excludeLanguages":["fr","en"]},{"matchPath":"/:lang?/categories/:uid","getLanguageFromPath":false,"excludeLanguages":["fr","en"]},{"matchPath":"/:lang?/products/:uid","getLanguageFromPath":false,"excludeLanguages":["fr","en"]},{"matchPath":"/:lang?/application","getLanguageFromPath":false,"excludeLanguages":["fr","en"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
