exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-templates-application-application-en-tsx": () => import("./../../../src/templates/Application/application.en.tsx" /* webpackChunkName: "component---src-templates-application-application-en-tsx" */),
  "component---src-templates-application-application-fr-tsx": () => import("./../../../src/templates/Application/application.fr.tsx" /* webpackChunkName: "component---src-templates-application-application-fr-tsx" */),
  "component---src-templates-categories-categories-en-tsx": () => import("./../../../src/templates/Categories/categories.en.tsx" /* webpackChunkName: "component---src-templates-categories-categories-en-tsx" */),
  "component---src-templates-categories-categories-fr-tsx": () => import("./../../../src/templates/Categories/categories.fr.tsx" /* webpackChunkName: "component---src-templates-categories-categories-fr-tsx" */),
  "component---src-templates-category-category-en-tsx": () => import("./../../../src/templates/Category/category.en.tsx" /* webpackChunkName: "component---src-templates-category-category-en-tsx" */),
  "component---src-templates-category-category-fr-tsx": () => import("./../../../src/templates/Category/category.fr.tsx" /* webpackChunkName: "component---src-templates-category-category-fr-tsx" */),
  "component---src-templates-home-home-en-tsx": () => import("./../../../src/templates/Home/home.en.tsx" /* webpackChunkName: "component---src-templates-home-home-en-tsx" */),
  "component---src-templates-home-home-fr-tsx": () => import("./../../../src/templates/Home/home.fr.tsx" /* webpackChunkName: "component---src-templates-home-home-fr-tsx" */),
  "component---src-templates-pregnancy-risks-pregnancy-risks-en-tsx": () => import("./../../../src/templates/PregnancyRisks/pregnancyRisks.en.tsx" /* webpackChunkName: "component---src-templates-pregnancy-risks-pregnancy-risks-en-tsx" */),
  "component---src-templates-pregnancy-risks-pregnancy-risks-fr-tsx": () => import("./../../../src/templates/PregnancyRisks/pregnancyRisks.fr.tsx" /* webpackChunkName: "component---src-templates-pregnancy-risks-pregnancy-risks-fr-tsx" */),
  "component---src-templates-product-product-en-tsx": () => import("./../../../src/templates/Product/product.en.tsx" /* webpackChunkName: "component---src-templates-product-product-en-tsx" */),
  "component---src-templates-product-product-fr-tsx": () => import("./../../../src/templates/Product/product.fr.tsx" /* webpackChunkName: "component---src-templates-product-product-fr-tsx" */)
}

